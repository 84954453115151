/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import IllnessJPG from '@assets/images/illness.jpg';
import IllnessMobileJPG from '@assets/images/illness-mobile.jpg';
import { ContainerSmall } from '~/utils/styles/utils';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/polyneuropathie';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/polyneuropathy';

const PolyneuropathyPage: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={IllnessJPG}
        mobileHero={IllnessMobileJPG}
        altHero="Modell eines Gehirns zur Krankheitserklärung von Polyneuropathie."
        enLink="/en/polyneuropathy/"
        deLink="/polyneuropathie/"
      >
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('PolyContent1') || tEn('PolyContent1'),
            }}
          />
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

export default PolyneuropathyPage;
